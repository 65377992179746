import { AdminMain } from "./pages/members/Main";
import { AboutUs } from "./pages/static/AboutUs";
import { Home } from "./pages/Home";
import { InvitePage } from "./pages/Invite";
import { LoginPage } from "./pages/Login";
import { ContactUs } from "./pages/static/ContactUs";
import { PrivacyPolicy } from "./pages/static/PrivacyPolicy";
import { Credo } from "./pages/static/Credo";
import { Statute } from "./pages/static/Statute";
import { NotFound } from "./pages/static/NotFound";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/about-us',
    element: <AboutUs />
  },
  {
    path: '/o-nas',
    element: <AboutUs />
  },
  {
    path: '/invite',
    element: <InvitePage />
  },
  {
    path: '/invite/:referral',
    element: <InvitePage />
  },
  {
    path: '/login',
    element: <LoginPage />
  },
  {
    path: '/admin',
    element: <AdminMain />
  },
  {
    path: '/contact-us',
    element: <ContactUs />
  },
  {
    path: '/kontakt',
    element: <ContactUs />
  },
  {
    path: '/privacy',
    element: <PrivacyPolicy />
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />
  },
  {
    path: '/credo',
    element: <Credo />
  },
  {
    path: '/statut',
    element: <Statute />
  },
  {
    path: '/regulamin',
    element: <Statute />
  },
  {
    path: '*',
    element: <NotFound />
  }
];

export default AppRoutes;
