import React from "react";
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";
import moment from 'moment';

export function MeetingsTable(props) {
    let meetings = props.meetings;
    let start = props.range.start;
    let end = props.range.end;
    let filterId = props.filter;
  
    return (
      <div>
        {/* {start.toString()} - {end.toString()} */}
        {meetings
          .filter(meeting => { return meeting.start.getTime() >= start.getTime() && meeting.start.getTime() <= end.getTime() })
          .filter(meeting => { return !filterId || meeting.id === filterId})
          .map(meeting => {
            return <MeetingCard meeting={meeting} key={meeting.id}/>
          })}
      </div>
    )
  }
  
  function MeetingCard(props) {
    let meeting = props.meeting;
    const meetingStart = moment(`${meeting.start}`).format('YYYYMMDDThhmmssZ');
    const meetingEnd = moment(`${meeting.end}`).format('YYYYMMDDThhmmssZ');
    const meetingLocation = meeting.online ? 'Meeting On-line' : (meeting.address ? `${meeting.address.street} ${meeting.address.number}, ${meeting.address.postalCode} ${meeting.address.city}` : '');
    return (
  
        <div> 
          <p>
            <button 
                className="card" 
                style={{width: "20rem"}} 
                type="button" 
                data-bs-toggle="collapse" 
                data-bs-target={`#meetingIdCollapse_${meeting.id}`} 
                aria-expanded="false" 
                aria-controls= {`meetingIdCollapse_${meeting.id}`} 
              >
              <h5 className="card-title">{meeting.title}</h5>
              <h6 className="card-subtitle mb-2 text-muted">Porwadzący: {meeting.host}</h6>
              <p className="card-text">Kiedy: {meeting.start.toLocaleString()} - {meeting.end.toLocaleTimeString()}</p>
            </button>
          </p>
          <div className="collapse" id={`meetingIdCollapse_${meeting.id}`} style={{width: "20rem"}}>
            <div className="card">
              <div className="card-body">
                <h6 className="card-subtitle mb-2 text-muted">Porwadzący: {meeting.host}</h6>
                <p className="card-text">Lokalizacja: {meetingLocation}</p>
                <p className="card-text">Kiedy: {meeting.start.toLocaleString()} - {meeting.end.toLocaleTimeString()}</p>
                <div className="card-test">Opis: <ReactMarkdown remarkPlugins={[remarkGfm]} >{meeting.description}</ReactMarkdown></div>
                <a href={`/invite?meeting=${meeting.id}`} className="btn btn-primary">Zapisz się</a>
                <a href={`https://calendar.google.com/calendar/u/0/r/eventedit?text=${meeting.title}
                &dates=${meetingStart}/${meetingEnd}
                &details=${encodeURIComponent(meeting.description)}
                &location=${encodeURIComponent(meetingLocation)}`} 
                target="_blank"
                rel="noreferrer"
                className="btn btn-primary">Dodaj do kalendarza</a><br/>
              </div>
            </div>
            <br/><br/>
          </div>
        </div>
    )
  }