import React, { useState, useEffect } from 'react'
import './styles/invite.css'
import { Formik, Form, Field } from 'formik';
import { validateCity, validateName, validateNip, validatePostcode, validateStreet, validateStreetNumber, validateSurname, validatePhone } from '../functions/validators';
import { addMeetingGuest, getMeetings, validateMember } from '../functions/ApiFetch';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import MeetingCard from '../components/MeetingCard';

export function InvitePage(props) {
    const navigate = useNavigate()
    const [faktura, setFaktura] = useState(false)
    const params = useParams()
    const [query, updateQuery] = useSearchParams()
    let [valid, setValid] = useState(true);
    const [meetings, setMeetings] = useState([]);
    const [currentRef, setCurrentRef] = useState(parseInt(query.get("meeting")));
    const [meeting, setMeeting] = useState(null)
    const nextMeeting = () => {
        let index = meetings.findIndex(meeting => meeting.id === currentRef);
        var newId = meetings[0].id
        if (typeof (meetings[index + 1]) !== typeof (undefined)) {
            newId = meetings[index + 1].id;
        }
        updateQuery({ "meeting": newId });
        setCurrentRef(newId)
        setMeeting(meetings.find(meeting => meeting.id === newId))
    }
    function previousMeeting() {
        let index = meetings.findIndex(meeting => meeting.id === currentRef);
        var newId = meetings[meetings.length - 1].id
        if (typeof (meetings[index - 1]) !== typeof (undefined)) {
            newId = meetings[index - 1].id;
        }
        updateQuery({ "meeting": newId });
        setCurrentRef(newId)
        setMeeting(meetings.find(meeting => meeting.id === newId))
    }
    useEffect(() => {
        if (params.referral) {
            validateMember(params.referral).then(
                result => setValid(result)
            );
        } else {
            setValid(true)
        }
        getMeetings().then(
            result => { setMeetings(result); return result }
        ).then(result => {
            if (isNaN(currentRef)) {
                updateQuery({ "meeting": result[0].id })
                setCurrentRef(parseInt(query.get("meeting")))
            }
            setMeeting(result.find(meeting => meeting.id === currentRef))
        })

    }, [params, query, currentRef, updateQuery])

    return (
        <div className="">
            {!valid && <div>We're sorry, your link appears to be broken! are you sure you pasted everything correctly?</div>}
            <h1>Witaj!</h1>
            {meeting && <MeetingCard meeting={meetings.find(meeting => meeting.id === currentRef)} />}
            <br />
            <button onClick={previousMeeting}>previous</button>
            <button onClick={nextMeeting}>next</button>
            <br />
            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    phone: '',
                    email: '',
                    address: {
                        city: '',
                        street: '',
                        number: '',
                        postcode: '',
                    },
                    nip: '',
                    ref: params.referral ?? '',
                    type: 'guest',
                }}
                onSubmit={async (values) => {
                    let [data, ok] = await addMeetingGuest(values, meeting.id);
                    if (ok) {
                        alert(`succesful: ${JSON.stringify(data)}`);
                        navigate('/');
                    } else {
                        alert(`form submission unsuccesful: ${JSON.stringify(data)}`)
                    }
                    
                }}
            >
                {({
                    values,
                    status,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) =>
                    <Form>
                        <label>Imię</label><div>
                            <Field
                                required
                                validate={validateName}
                                as="input"
                                label="Imię"
                                name="firstName"
                                placeholder="Jan" />
                            {errors.firstName && touched.firstName && `error: ${errors.firstName}`}
                        </div>

                        <label>Nazwisko</label><div>
                            <Field
                                required
                                validate={validateSurname}
                                as="input"
                                name="lastName"
                                placeholder="Kowalski" />
                            {errors.lastName && touched.lastName && `error: ${errors.lastName}`}
                        </div>

                        <label>Nr. Telefonu</label><div>
                            <Field
                                required
                                validate={validatePhone}
                                as="input"
                                name="phone"
                                placeholder="e.g. 1234567890"
                            />
                            {errors.phone && touched.phone && `error: ${errors.phone}`}
                        </div>

                        <label>Mail</label><div>
                            <Field
                                required
                                type="Email"
                                name="email"
                                placeholder="Twójmail@przykład.com" />
                        </div>



                        <label>Faktura: </label><div>
                            <input type="checkbox" onChange={() => {
                                let prev = faktura
                                if (prev) {
                                    values.nip = ''
                                    values.address = {
                                        city: '',
                                        street: '',
                                        number: '',
                                        postcode: '',
                                    }
                                }
                                setFaktura(!prev);

                            }} />
                            {faktura && (
                                <div>
                                    <label>Dane do faktury</label>
                                    <br />
                                    <label>Adres</label>
                                    <br />
                                    {errors.address && touched.address && Object.values(errors.address).map(error => <><text>{error}</text><br /></>)}
                                    <div>
                                        <Field
                                            required={faktura}
                                            validate={validatePostcode}
                                            as="input"
                                            name="address.postcode"
                                            placeholder="Kod pocztowy (55-555)" />
                                        <Field
                                            required={faktura}
                                            validate={validateCity}
                                            as="input"
                                            name="address.city"
                                            placeholder="Miasto" />
                                    </div>
                                    <div>
                                        <Field
                                            required={faktura}
                                            validate={validateStreet}
                                            as="input"
                                            name="address.street"
                                            placeholder="Ulica" />
                                        <Field
                                            required={faktura}
                                            validate={validateStreetNumber}
                                            as="input"
                                            name="address.number"
                                            placeholder="Numer (3, 12b etc.)" />
                                    </div>
                                    <label>NIP</label>
                                    <div>
                                        <Field
                                            required={faktura}
                                            validate={validateNip}
                                            as="input"
                                            name="nip"
                                            placeholder="NIP ex. 1542345"
                                        />
                                        {errors.nip && touched.nip && `error: ${errors.nip}`}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div>
                            <button type="submit">Wyślij</button>
                        </div>

                    </Form>
                }

            </Formik>
        </div>
    )
}
export default InvitePage;