import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";

export const MeetingCard = ({ meeting, ...props }) => {
    const meetingLocation = meeting.online ? 'Meeting On-line' : (meeting.address ? `${meeting.address.street} ${meeting.address.number}, ${meeting.address.postalCode} ${meeting.address.city}` : '');
    return (

        <div>
            <p>
                <button
                    className="card"
                    style={{ width: "20rem" }}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#meetingIdCollapse_${meeting.id}`}
                    aria-expanded="false"
                    aria-controls={`meetingIdCollapse_${meeting.id}`}
                >
                    <h5 className="card-title">{meeting.title}</h5>
                    <h6 className="card-subtitle mb-2 text-muted">Porwadzący: {meeting.host}</h6>
                    <p className="card-text">Kiedy: {meeting.start.toLocaleString()} - {meeting.end.toLocaleTimeString()}</p>
                    <p className="card-text">Lokalizacja: {meetingLocation}</p>
                    <div className="card-test">Opis: <ReactMarkdown remarkPlugins={[remarkGfm]} >{meeting.description}</ReactMarkdown></div>
                </button>
            </p>
        </div>
    )
}

export default MeetingCard;