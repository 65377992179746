import { useState } from "react"
import { MeetingsPanel } from "../../components/admin/MeetingTile"
import { CoresPanel } from "../../components/admin/CoresPanel"
import "./styles/main.css"
import {getMeetings, getCoreGuests} from "../../functions/ApiFetch"

export function AdminMain(props) {
    let [mtgs, setMtgs] = useState([]);
    let [cores, setCores] = useState([]);
    let [dirty, setDirty] = useState(true)
    function toggleDirty() { let newDirty = !dirty;  setDirty(newDirty)}
    if (dirty) {
        getMeetings().then((r) => { setMtgs(r) });
        getCoreGuests().then((r) => { setCores(r) });
        toggleDirty();
    }
    return (
        <div className="MainPane">
            <div className="MeetingsPane">
                <MeetingsPanel meetings={mtgs} dirty={toggleDirty} />
            </div>
            <div className="CoresPane">
                <CoresPanel guests={cores} dirty={toggleDirty} />
            </div>
        </div>
    )
}
