import Address from "./Address";

export default class Meeting {
    id = NaN;
    title = "";
    start = new Date();
    end = new Date();
    link = ''
    host = '';
    address = null
    online = false;
    description = ''

    set startFromString(dateString) {
        this.start = new Date(dateString)
    }

    set endFromString(dateString) {
        this.end = new Date(dateString)
    }

    toApiObject() {
        let exportObj = {
            id: this.id,
            title: this.title,
            startDate: this.start,
            durationMinutes: Math.floor((this.end - this.start) / 1000 / 60),
            address: this.address,
            link: this.online ? this.link : null,
            host: this.host,
            description: this.description
        }
        return exportObj
    }

    constructor(meetingObject) {
        // console.log(`destructuring meeting from: ${JSON.stringify(meetingObject)}`);
        // let { id, title, startDate, durationMinutes, address, link, host, description } = meetingObject;
        this.id = meetingObject.id;
        this.title = meetingObject.title;
        this.start = new Date(meetingObject.startDate);
        this.end = new Date(this.start.getTime() + (meetingObject.durationMinutes ?? 60) * 60 * 1000);
        this.host = meetingObject.host;
        if (meetingObject.link) {
            this.link = meetingObject.link;
            this.online = true;
        } else {
            this.online = false
            if (meetingObject.address) {
                this.address = new Address(meetingObject.address);
            }
        }
        this.description = meetingObject.description;
        Object.seal(this)
    }
}