export function validateName(name) {
    if (/[^a-zA-Z]/g.test(name)) {
        return "please include only letters"
    }
    if (!/^[A-Z]/.test(name)) {
        return "Names start with an uppercase letter"
    }
    if (name.length > 20) {
        return "name too long"
    }
    if (/^[A-Z]+[a-z]{1,20}/.test(name)) {
        return null
    } else {
        return "invalid name"
    }
}

export function validateSurname(name) {
    if (/[^a-zA-Z-[:space:]]/g.test(name)) {
        return "please include only letters"
    }
    if (!/^[A-Z]/.test(name)) {
        return "Names start with an uppercase letter"
    }
    if (name.length > 35) {
        return "name too long"
    }
    if (/^[A-Z][a-z]{1,10}((\s[A-Z][a-z]{1,15}|-[a-zA-Z][a-z]{1,15}|[a-z]{1,15}|){0,2})$/.test(name)) {
        return null
    } else {
        return "invalid surname"
    }
}

export function validateCity(name) {
    if (!/^[A-Z][a-z]{1,15}((\s[a-z]{1,5}|-[A-Z][a-z]{1,15}|\s[A-Z][a-z]{1,15}){0,4})$/.test(name)) {
        return "Please enter a valid city name"
    } else {
        return null
    }
}

export function validateStreet(name) {
    if (!/^[a-zA-Z0-9-\s]{3,40}$/.test(name)) {
        return "please enter a valid street name"
    } else {
        return null
    }
}

export function validateStreetNumber(num) {
    if (!/^[0-9]{1,3}[A-z]{0,1}$/.test(num)) {
        return "please enter a valid street number"
    } else {
        return null
    }
}

export function validatePostcode(num) {
    if (!/^[0-9]{2}-[0-9]{3}$/.test(num)) {
        return "please enter a valid postcode"
    } else {
        return null
    }
}

export function validateNip(num) {
    if (!/^[0-9]{10}$/.test(num)) {
        return "please enter a valid NIP number (10 digits)"
    } else {
        return null
    }
}

export function validatePhone(phone) {
    if (!/^[0-9]{9,11}$/.test(phone)) {
        return "please enter a valid phone number (with country code component)"
    } else {
        return null
    }
}
