import React, { useState } from "react";
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import "react-big-calendar/lib/css/react-big-calendar.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { MeetingsTable} from './MeetingTable'
import { getMeetings } from '../functions/ApiFetch'
//deklaracja języka, zmienia czas z 12h na 24h
// sprawdzić jak prawidlowo wprowadzać język kalendarza (culture=?)

// moment.locale([], {
//   week: {
//     dow: 1,// Monday is the first day of the week.
//     doy: 1,// Used to determine first week of the year.
//   },
// });

const localizer = momentLocalizer(moment);

export function MyCalendar() {
  const now = new Date();
  const [range, setRange] = useState({ start: new Date(now.getFullYear(), now.getMonth(), 1), end: new Date(now.getFullYear(), now.getMonth()+1, 0) });
  const [dirty, setDirty] = useState(true);
  const [meetings, setMeetings] = useState([]);
  const [filterId, setFilterId] = useState(null);

  if (dirty) {
    getMeetings().then(a => setMeetings(a));
    setDirty(false);
  
  }

  return (
    <div className="myCustomHeight">
      <div>
        <Calendar
          views={{ month: true }[Views.MONTH]}
          onRangeChange={(data) => {
            setRange(data)
            setFilterId(null)
          }}
          toolbar={true}
          localizer={localizer}
          events={meetings}
          startAccessor="start"
          endAccessor="end"
          onShowMore={(data) => { console.log({ "more": data }) }}
          onSelectEvent={(data) => setFilterId(data.id) }
          style={{ height: 500, margin: "50px" }}
          popup={true}

        />
      </div>
      <MeetingsTable meetings={meetings} range={range} filter={filterId}/>
    </div>
            
  );
};

