import {useEffect, useState} from "react";
import './styles/slider.css';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import img1 from '../media/images/img1.jpg'
import {getSliderItems} from '../functions/ApiFetch'

export function NewSlider(){
    const [images, setImages] = useState([]);


    useEffect(() => {
        getSliderItems().then(images => setImages(images)) ;
    }, [])

      return(
        <div className="Slider">{
          images.length === 0 ? <img src={img1} alt={"placeholder for loading"} /> : ""
            }
            <AliceCarousel
              infinite
              autoPlay
              autoPlayInterval="3000"
            items={images.map(image => <div>
                <img src={image.url} alt={image.image} className="sliderimg" />
                {(image.action === 'meeting' || image.action === 'navigate') && image.argument ? <a className="btn btn-primary" href={image.argument}>Więcej/Zapisz się</a> : null }
                </div>)}
              />
        </div>
    )
}

