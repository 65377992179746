import { useState } from "react";
import "./styles/CoresPanel.css"
import GuestDetails from "./GuestDetails";
import {removeCoreGuest, editCoreGuest, addCoreGuest, notifyMember} from "../../functions/ApiFetch";

export function CoresPanel({guests, dirty, ...props}) {
    let [edit, setEdit] = useState(false)
    let [currentGuest, setCurrent] = useState(null)
    function toggleEdit() { let newEdit = !edit; setEdit(newEdit) }
    return (
        <div className="Main">
            {!edit ?
                <>
                    <h1> Core Guests: </h1>
                    <ul className="CoresList">
                        {guests.map((gst) => {
                            return <CoreTile guest={gst} key={gst.id} onDetails={(guest) => { setCurrent(guest);  toggleEdit() }} />
                        })}
                    </ul>
                    <br />
                    <button onClick={toggleEdit}> add new </button>
                </>

                :
                <GuestDetails
                    guest={currentGuest}
                    onSubmit={async (values, toAdd) => {
                        setCurrent(null)
                        if (toAdd) {
                            let [code, data] = await addCoreGuest(values);
                            if (code !== 201) {
                                alert(`something went wrong!\n(error ${code}: ${data.info})`)
                            } else {
                                alert("dodanie goscie zakonczone sukcesem")
                            }
                        } else {
                            let [code, data] = await editCoreGuest(values);
                            alert(`${code}: ${JSON.stringify(data)}`)
                        }
                        toggleEdit();
                        dirty()
                    }}
                    onNotifyMail={(id) => { 
                        notifyMember(id).then(
                            data => alert(JSON.stringify(data))
                        )
                    }}
                    onCancel={() => { setCurrent(null); toggleEdit() }}
                    onDelete={async (guest) => {
                        setCurrent(null);
                        const [code, data] = await removeCoreGuest(guest.id);
                        if (code !== 200) {
                            alert(`cos poszlo nie tak!\n(Error ${code}: ${data.info})`)
                        } else {
                            alert('usuniecie goscia zakonczone sukcesem')
                        }
                        toggleEdit()
                        dirty()
                    }}
                />

            }

        </div>
    )
}

function CoreTile({guest, onDetails, dirty, ...props}) {
    return (
        <div className="GuestTile">
            <text>{guest.firstName} {guest.lastName}</text>
            <br/>
            <button onClick={() => {onDetails(guest)}}> details </button>
        </div>

    )
}