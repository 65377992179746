import React, { Component } from 'react';
import {MyCalendar} from '../components/Calendar';
import { Container } from 'reactstrap';
import {NewSlider} from '../components/NewsSlider'



export class Home extends Component {
  static displayName = Home.name;

  constructor(props) {
    super(props);
    this.state = { visible: false, finished: false };  
  }

  setVisible() {
    let value = this.state.visible;
    this.setState({visible: !value});
  }

  render() {
    return (
      <Container>
      <div>
        <div>
          <main>
            <NewSlider/>
            <h2>Kalendarz spotkań</h2><br/>
          <div>
          <MyCalendar/><br/><br/></div>
          
        </main>
        </div>
      </div>
      <div> </div>
      </Container>
    );
  }
}