import { Formik, Form, Field } from "formik"
import { useEffect, useState } from "react";
import Meeting from "../../entities/Meeting"
import ReactMarkdown from 'react-markdown'
import remarkGfm from "remark-gfm";
import { validatePostcode, validateStreet, validateStreetNumber, validateCity } from '../../functions/validators'

export default function MeetingDetails({ meeting, details, onSubmit, onCancel, onMeetingRemove, onGuestRemove, onGuestsNotify, ...props }) {
    const startTime = meeting ? meeting.start.toLocaleTimeString([], { timeStyle: 'short', hour12: false }) : "00:00";
    const endTime = meeting ? meeting.end.toLocaleTimeString([], { timeStyle: 'short', hour12: false }) : "00:00";
    const [editable, setEdit] = useState(meeting === null);
    const [online, setOnline] = useState(meeting ? meeting.online : false);
    const [preview, setPreview] = useState(editable);
    const [guests, setGuests] = useState([])
    function toggleOnline() { let newOnline = !online; setOnline(newOnline) }
    function toggleEdit() { let newEdit = !editable; setEdit(newEdit); setPreview(true) }
    function togglePreview() { let newPreview = !preview; setPreview(newPreview) }
    useEffect(() => {
        if (details != null) {
            details.then(
                data => setGuests(data.participants)
            );
        }
    }, [details])

    return (
        <div>
            <div>
                {meeting ? <button onClick={toggleEdit}>{editable ? "editing" : "viewing"}</button> : null}
                <button type="submit" onClick={() => window.location.reload()}>Powrót</button>               
                <Formik
                    initialValues={{
                        id: meeting ? meeting.id : undefined,
                        durationMinutes: meeting ? meeting.durationMinutes : undefined,
                        title: meeting ? meeting.title : '',
                        date: meeting ? meeting.start.toJSON().split('T')[0] : null,
                        start: startTime,
                        end: endTime,
                        online: meeting ? meeting.online : false,
                        address: meeting ? meeting.address : { city: '', street: '', number: '', postalCode: '' },
                        link: meeting ? meeting.link : '',
                        host: meeting ? meeting.host : '',
                        description: meeting ? meeting.description : ''
                    }}
                    onSubmit={(values) => {
                        if (meeting === null) {
                            let newMeeting = new Meeting(values)
                            newMeeting.startFromString = `${values.date}t${values.start}`
                            newMeeting.endFromString = `${values.date}T${values.end}`
                            onSubmit(newMeeting, meeting === null)
                        } else {
                            let end = new Date(`${values.date}T${values.end}`)
                            let start = new Date(`${values.date}T${values.start}`)
                            values.durationMinutes = ( end - start ) / 1000 / 60;
                            onSubmit(values, meeting === null)
                        }
                    }}
                    onReset={(values, formikBag) => {
                        values.title = meeting.title || '';
                        values.date = meeting.start.toJSON().split('T')[0];
                        values.start = startTime;
                        values.end = endTime;
                        values.address = meeting.address || { city: '', street: '', number: '', postcode: '' };
                        values.link = meeting.link || '';
                        values.host = meeting.host || '';
                        values.description = meeting.description || '';
                        values.online = meeting.online;
                        setOnline(meeting.online)
                    }}
                    initialTouched={false}
                >
                    {({
                        values,
                        dirty,
                        touched,
                        setTouched,
                        handleReset,
                        errors,
                    }) =>
                        <Form>
                            <label>Title {online}</label>
                            <Field
                                disabled={!editable}
                                required
                                type="text"
                                name="title"
                                placeholder="tytul spotkania"
                            />
                            <br />
                            <label>Data</label>
                            <Field
                                disabled={!editable}
                                required
                                type="date"
                                name="date"
                                placeholder="tytul spotkania"
                            />
                            <br />
                            <label>Czas</label>
                            <Field
                                disabled={!editable}
                                required
                                type="time"
                                name="start"
                            />
                            -
                            <Field
                                disabled={!editable}
                                required
                                type="time"
                                name="end"
                            />
                            <br />
                            <button type="button" disabled={!editable} onClick={toggleOnline}>{online ? "Online" : "Not online"}</button>
                            {!online ?
                                <div>
                                    {errors.address && Object.values(errors.address).map(e => <><text>{e}</text><br /></>)}
                                    <label>Adres</label>
                                    <br />
                                    <Field
                                        disabled={!editable}
                                        validate={validatePostcode}
                                        required
                                        type="text"
                                        name="address.postalCode"
                                        placeholder="kod pocztowy"
                                    />
                                    <Field
                                        disabled={!editable}
                                        validate={validateCity}
                                        required
                                        type="text"
                                        name="address.city"
                                        placeholder="miasto"
                                    />
                                    <br />
                                    <Field
                                        disabled={!editable}
                                        validate={validateStreet}
                                        required
                                        type="text"
                                        name="address.street"
                                        placeholder="ulica"
                                    />
                                    <Field
                                        disabled={!editable}
                                        validate={validateStreetNumber}
                                        required
                                        type="text"
                                        name="address.number"
                                        placeholder="numer (np. 11B)"
                                    />
                                    <br />
                                </div>
                                :
                                <div>
                                    <label>Link do spotkania online:</label>
                                    <Field
                                        disabled={!editable}
                                        required
                                        type="text"
                                        name="link"
                                    />
                                </div>
                            }
                            <label>Host spotkania:</label>
                            <br />
                            <Field
                                disabled={!editable}
                                required
                                type="text"
                                name="host"
                                placeholder="Host spotkania"
                            />
                            <br />
                            <label>Opis spotkania:</label>
                            <br />
                            <button type="button" disabled={!editable} onClick={togglePreview}>{preview ? "podglad" : "edycja"}</button>
                            <br />
                            {preview || !editable ?
                                <ReactMarkdown remarkPlugins={[remarkGfm]} >{values.description}</ReactMarkdown>
                                :
                                <Field
                                    required
                                    name="description"
                                    component={(props) => <textarea {...props} onChange={(text) => { values.description = text.target.value; }} rows={7} cols={40} placeholder="opis spotkania (Markdown wspierany)">{values.description}</textarea>}
                                />
                            }
                            <br />
                            {editable ?
                                <>
                                    <button type="submit">{meeting ? "Zapisz zmiany" : "Dodaj Spotkanie"}</button>
                                    {meeting ? <button type="button" onClick={() => handleReset()}> przywroc zmiany</button> : null}

                                </>
                                :
                                null
                            }
                            <button type="button" onClick={() => {
                                if (dirty || touched.description) {
                                    let opt = window.confirm(`Masz niezapisane zmiany, opuscic? \nNiezapisane zmiany w: \n${Object.keys(touched).join('\n')}`)
                                    if (!opt) {
                                        return
                                    }
                                }
                                onCancel()
                            }}>cancel</button>
                            {meeting ? <button type="button" onClick={() => {
                                let conf = window.confirm(`czy na pewno chcesz usunac to spotkanie?: \n ${(meeting.title)}`)
                                if (conf) {
                                    onMeetingRemove(meeting)
                                }
                                return
                            }}>Usun Spotkanie</button> : null}
                        </Form>
                    }
                </Formik>
                <br />
                {/* guests: {guests.then(a => JSON.stringify(a))} */}
            </div>
            {meeting && <button onClick={ () => onGuestsNotify(meeting.id)}>Wyślij E-maile z zaproszeniami</button>}
            <MeetingGuests guests={guests} onGuestRemove={(id) => onGuestRemove(id)} />
        </div>
    )
}

export const MeetingGuests = ({ guests, onGuestRemove, onGuestsNotify, ...props }) => {
    const members = guests.filter(guest => guest.type === "member")
    return (
        <div>
            {guests.map(guest => <GuestTile guest={guest} members={members} key={guest.id} onRemove={(id) => onGuestRemove(id)} />)}
        </div>
    )
}

export const GuestTile = ({ guest, members, onRemove, ...props }) => {
    const [deleted, setDeleted] = useState(false);
    function toggleDeleted() { setDeleted(!deleted) }
    return (
        <div>
            <text>{!deleted ? "ZAPROSZONY" : "USUNIĘTY"}</text>
            <text>{guest.firstName} {guest.lastName}</text>
            <br/>
            {guest.type !== "member" && guest.ref ?
                <>
                    <text>z referencji: </text>
                    <ReferenceTile member={members.find(member => member.id === guest.ref)} />
                </>
                :
                null}
            {guest.type !== "member" ? <button onClick={() => { toggleDeleted(); onRemove(guest.id) }} >{!deleted ? "usuń ze spotkania" : "przywróć"}</button> : null}
        </div>
    )
}

export const ReferenceTile = ({ member, ...props }) => {
    return (
        <div>
            {member.firstName} {member.lastName}
        </div>
    )
}