import "./styles/MeetingTile.css"
import React, { useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from "moment"
import MeetingDetails from "./MeetingDetails"
import { addMeeting, editMeeting, getMeeting, notifyGuests, removeMeeting, removeMeetingGuest, fetchLogout } from "../../functions/ApiFetch"
import { useNavigate } from "react-router-dom";

export function MeetingsPanel({meetings, dirty, ...props }) {
    const [editMode, setEdit] = useState(false);
    const [currentMeeting, setCurrent] = useState(null);
    function toggleEdit() { let newEdit = !editMode; setEdit(newEdit) }
    const localizer = momentLocalizer(moment);
    const navigate = useNavigate();

    return (
    <>
            {!editMode ?
                <div>

                    <button onClick={toggleEdit}> New meeting </button>
                    <button onClick={async () =>{
                        let status = await fetchLogout();
                        if (status){
                            return navigate('/');
                        }else{
                            return console.log(status)
                        }
                    } }>Logout</button>
                    <Calendar

                        views={{ month: true, week: true, day: true }}
                        onDrillDown={(a) => { alert(JSON.stringify(a)) }}
                        style={{ height: `85vh` }}
                        localizer={localizer}
                        events={meetings}
                        startAccessor="start"
                        endAccessor="end"
                        popup={true}
                        onSelectEvent={(event) => { setCurrent(event); toggleEdit() }}
                    />
                </div>
                :
                <MeetingDetails
                    dirty={dirty}
                    meeting={currentMeeting}
                    details={currentMeeting ? getMeeting(currentMeeting.id) : null}
                    onGuestRemove={(id) => removeMeetingGuest(currentMeeting.id, id)}
                    onSubmit={async (event, toAdd) => {
                        if (toAdd) {
                            let [status, data] = await addMeeting(event);
                            if (status === 201) {
                                alert("new meeting addition succesful")
                            } else {
                                alert(`An error ocurred while adding meeting!\n(error ${status}: ${data.info})`)
                            }
                        } else {
                            let [status, data] = await editMeeting(event)
                            if (status === 200) {
                                alert("meeting edit succesful")
                            } else {
                                alert(`An error ocurred while adding meeting!\n(error ${status}: ${data.info})`)
                            }
                        };
                        toggleEdit();
                        dirty();
                    }}
                    onCancel={() => { setCurrent(null); toggleEdit() }}
                    onMeetingRemove={(meeting) => {
                        toggleEdit();
                        removeMeeting(meeting.id).then(([status, data]) => {
                            if (status !== 200) {
                                alert("usuniecie spotkanie nie powiodlo sie")

                            }
                            dirty()
                        })
                    }}
                    onGuestsNotify={(id) => {
                        notifyGuests(id).then(data => {
                            alert(JSON.stringify(data))
                        })
                    }}
                />
            }
        </>
    )
}