import React from 'react';

export function AboutUs() {
  return (
    <div className="container">
      <div>
        <h1>O klubie</h1>
      </div>
      <div>
        <p>
          <strong>Przez Rekomendacje</strong> to portal agregujący informacje o spotkaniach networkingowych z przeróżnych grup z całej Polski.
          Zarejestruj się, aby otrzymywać powiadomienia o interesujących Cię wydarzeniach online lub stacjonarnych.
        </p>

        <p>
          Klub biznesowy to organizacja, która skupia ludzi z różnych dziedzin, którzy chcą poznać się lepiej, łączyć swoje biznesy i rekomendować swoje usługi. Klub organizuje spotkania raz w miesiącu, aby umożliwić członkom rozwijanie swoich relacji biznesowych i nawiązywanie nowych kontaktów.
          Spotkania klubu to doskonała okazja do poszerzania swojej sieci kontaktów. Można tu poznać ludzi, którzy pracują w różnych branżach i mają niespotykane umiejętności. To może być cenny sposób na znalezienie nowych klientów lub partnerów.
        </p>

        <p>
          Uczestnicto w spotkaniach klubu daje członkom możliwość dzielenia się swoimi doświadczeniami i pomysłami. Może to stać się źródłem ciekawych dyskusji, innowacyjnych pomysłów i inspiracji w dalszym działaniu.
          Członkowie klubu pomagają sobie nawzajem, dzieląc się swoimi kontaktami i rekomendując swoje usługi.
        </p>

        <p>
          <div>Przez Rekomendacje</div> to miejsce, gdzie można zbudować długotrwałe relacje biznesowe. W klubie członkowie mogą poznać ludzi, którzy podzielają ich wartości i cele. To może prowadzić do nawiązania cennych relacji biznesowych, które będą przynoszyć korzyści przez wiele lat.
        </p>

        <p>
          Klub biznesowy to również doskonała okazja do edukacji i rozwoju. Kluby biznesowe często organizują prezentacje, szkolenia i warsztaty, które pomagają członkom rozwijać swoje umiejętności i wiedzę. To może być sposób na poznanie nowych trendów w swojej dziedzinie i na rozwijanie swojego biznesu.
        </p>
      </div>
    </div>
  )
}


