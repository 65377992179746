import Meeting from "../entities/Meeting"
import User from "../entities/User"
export const api = "/api"

//Calendar.js
export async function getMeetings() {
    const response = await fetch(`${api}/meetings`);
    const data = await response.json();
    if (response.ok) {
        const meetings = data.map(dt => new Meeting(dt))
        return meetings
    }
    return data;
}

export async function editCoreGuest(guest) {
    const response = await fetch(`${api}/members/${guest.id}`, {
        method: 'put',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(guest),
    })
    const data = await response.json();
    return [response.status, data];
}

//CoresPanel.js
export async function removeCoreGuest(id) {
    let res = await fetch(`${api}/members/${id}`,
        {
            method: "delete",
        }
    );
    let data = await res.json()
    return [res.status, data]
}

//GuestView.js
export async function addCoreGuest(guest) {
    let res = await fetch(`${api}/members`, {
        body: JSON.stringify(guest),
        method: 'post',
        headers: {
            "Content-Type": "application/json"
        }

    })
    let data = await res.json()
    return [res.status, data]
}

export async function notifyGuests(id) {
    let res = await fetch(`${api}/meetings/${id}/notify`, {
        method: 'get'
    })
    let data = await res.json();
    return data;
}

export async function notifyMember(id) {
    let res = await fetch(`${api}/members/${id}/notify`, {
        method: 'get'
    })
    let data = await res.json();
    return data;
}

export async function getMeeting(id) {
    let res = await fetch(`${api}/meetings/${id}`,
        {
            method: 'get',
        }
    )
    let data = await res.json();
    return data
}

export async function getMeetingGuests(id) {
    let res = await fetch(`${api}/meetings/${id}/guests`,
        {
            method: 'get',
        }
    )
    let data = await res.json()
    if (res.ok) {
        const guests = data.map(dt => new User(dt))
        return guests
    }
    return data
}

export async function editMeetingGuest(id, guest) {
    console.error("method unimplemented!!!")
}

export async function removeMeetingGuest(id, gid) {
    let res = await fetch(`${api}/meetings/${id}/guests/${gid}`,
        {
            method: 'delete',
        }
    )
    let data = await res.json()
    return [res.status, data]
}

export async function editMeeting(meeting) {
    const response = await fetch(`${api}/meetings/${meeting.id}`, {
        method: 'put',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(meeting)
    })
    const data = await response.json()
    return [response.status, data]
}

export async function removeMeeting(id) {
    let res = await fetch(`${api}/meetings/${id}`, {
        method: 'delete',
    });
    let data = await res.json();
    return [res.status, data]
}

export async function addMeeting(meeting) {

    let res = await fetch(`${api}/meetings`,
        {
            method: 'Post',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(meeting.toApiObject())
        }
    );
    let data = await res.json();
    return [res.status, data]
}

export async function getCoreGuests() {
    let res = await fetch(`${api}/members`, {
        method: "get",
    });
    let data = await res.json();
    if (res.ok) {
        return data;
    }
    return [res.status, data];
}

//Invite.js
export async function addMeetingGuest(guest, id) {
    // TODO where to add referer token (_needed for refactor too)
    let res = await fetch(`${api}/meetings/${id}/guests`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(guest)
    });
    let data = await res.json();
    return [data, res.ok];
}

//Login.js
export async function fetchAuth(username, password) {
    // console.log(username, password, encoded)
    let authRes = fetch(`${api}/authorize`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ login: username, password: password, type: "cookie" })
    })
    let response = await authRes;
    let obj = await response.json();
    return response.status;
}

export async function validateMember(referralString) {
    let response = await fetch(`${api}/members/validate?referrer=${referralString}`, {
        method: "GET"
    });
    return (response.status === 200 ? true : false);
}

//NewSlider.js
export async function getSliderItems() {
    let res = await fetch(`${api}/slider/carousel`, {
        method: "get"
    });
    let data = await res.json();
    return data;
}

export async function removeImage(id, token) {
    let res = await fetch(`${api}/slider/${id}`, {
        method: "delete",
    });
    let data = await res.json()
    return [res.status, data]
}

export async function addImage(media, token) {
    let res = await fetch(`${api}/slider/add`, {
        method: 'post',
    });
    let data = await res.json()
    return [res.status, data]
}

//Logout
export async function fetchLogout(){
    let logoutres = await fetch(`${api}/auth/logout`, {
        method: 'get',
    });
    return (logoutres.status === 200 ? true : false);
}

export async function sendContactMessage(content)
{
  let res = await fetch(`${api}/mails/contact`,
  {
    method: 'POST',
    headers: {
        "content-type" : "application/json"
    },
    body: JSON.stringify(content)
  }
  );
  let data = await res.json();
  return [data, res.ok];
}
