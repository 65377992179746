import React from "react";
import { Formik, Form, Field } from "formik";
import { validateName, validatePhone, validateSurname } from "../../functions/validators";
import { sendContactMessage } from "../../functions/ApiFetch";

const required = "To pole jest wymagane";

const errorMessage = error => {
  return <div>{error}</div>;
};

const validateEmail = value => {
  let error;
  if (!value) {
    error = required;
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = "Nieprawidłowy adres email";
  }
  return error;
};

export function ContactUs() {

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        mobileNumber: "",
        email: "",
        servicePrivacy: ""
      }}

      onSubmit={sendContactMessage}
    >
      {({ errors, touched, isValidating, isValid }) => (
        <div className="container">
          <div className="col-sm-12">
            <h3>Wyślij pytanie</h3>
          </div>
          <div className="col-sm-12">
            <Form >
              <div className="form-group">
                <Field
                  required
                  className="form-control"
                  type="text"
                  placeholder="Jan"
                  name="firstName"
                  validate={validateName}
                />
                {errors.firstName && touched.firstName && errorMessage(errors.firstName)}
              </div>
              <div className="form-group">
                <Field
                  required
                  className="form-control"
                  type="text"
                  placeholder="Kowalski"
                  name="lastName"
                  validate={validateSurname}
                />
                {errors.lastName && touched.lastName && errorMessage(errors.lastName)}
              </div>
              <div className="form-group">
                <Field
                  required
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="twój-mail@domena.com"
                  validate={validateEmail}
                />
                {errors.email && touched.email && errorMessage(errors.email)}
              </div>
              <div className="form-group">
                <Field
                  required
                  className="form-control"
                  type="tel"
                  placeholder="e.g. +48 111222333"
                  name="mobile"
                  validate={validatePhone}
                />
                { errors.mobile && touched.mobile && errorMessage(errors.mobile) }
              </div>
              <div className="form-group">
                <Field
                  component="textarea"
                  className="form-control"
                  placeholder="Wiadomość"
                  name="message"
                />
              </div><br/>
              <div className="form-group">
                <Field
                  type="checkbox"
                  placeholder="service privacy"
                  name="servicePrivacy"
                  id="serviceCheck"
                  required
                />
                <label htmlFor="serviceCheck">Akceptuję warunki serwisu</label>
              </div>
              <div className="form-group"><br/>
                <button className="btn btn-primary" type="submit">
                  Wyślij
                </button>
              </div>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  );
}

export default ContactUs;
