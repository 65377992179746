import { useState } from "react"
import { useNavigate, useLocation } from "react-router-dom";
import {fetchAuth} from "../functions/ApiFetch";

export const LoginPage = () => {
        return (
            <div>
                <h1>Moje konto - logowanie</h1>
                <PasswordGate />
            </div>
        )
}

const PasswordGate = (props) => {
    let loc = useLocation();
    let onRoute = atob(loc.hash.replace("#", ""));
    console.log(onRoute)
    const [username, setUname] = useState("");
    const [password, setPass] = useState("");
    const [err, setErr] = useState(onRoute);
    const navigate = useNavigate()

    return (
        <div>
            {err !== "" ? <text>{err}</text> : null}
            <div>
                <label>Login: </label>
                <input type="text" placeholder="username" onChange={(e) => { setUname(e.target.value) }} />
            </div>
            <br/>
            <div>
                    <label>Haslo: </label>
                <input type="password" placeholder="password" onChange={(e) => { setPass(e.target.value) }} />
            </div>
            <br />
                <button onClick={async () => {
                    let status = await fetchAuth(username, password);
                if (status === 200) {
                        return navigate('/admin');
                    } else {
                        setErr(`Authentication unsuccessful (error code ${status})`);
                    }
                }}> Login </button>
        </div>
        )
}
