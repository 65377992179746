import { React, Component } from 'react';

export class Footer extends Component {

    render() {
        return <div>
            <a href="/privacy-policy" alt="Polityka Prywatności">Polityka Prywatności</a> | <a href="/regulamin" alt="Regulamin">Regulamin</a> | <a href="/credo" alt="Credo">Credo</a>
            <br />
            Copyright © 2020 - 2023 <a href="https://codetitans.pl" alt="CodeTitans">CodeTitans Sp. z o.o.</a>
        </div>
    }
}