export default class Address {
    city = '';
    street = '';
    number = '';
    postalCode = '';

    constructor(locationObject) {
        // console.log(`destructuring location ${locationObject}`);
        // let { city, street, number, postcode } = locationObject;
        this.city = locationObject.city;
        this.street = locationObject.street;
        this.number = locationObject.number;
        this.postalCode = locationObject.postalCode;
        Object.seal(this)
    }
}