import { useState } from "react"
import { Formik, Form, Field } from "formik";

export default function GuestDetails({ guest, onSubmit, onCancel, onDelete, onNotifyMail, ...props }) {
    const [edit, setEdit] = useState(guest === null)
    function toggleEdit() { let newEdit = !edit; setEdit(newEdit) }
    return (
        <div>
            {guest ? <button onClick={toggleEdit}>{edit ? 'Editing' : 'Viewing'}</button> : null}
            <Formik
                initialValues={{
                    id: guest ? guest.id : undefined,
                    firstName: guest ? guest.firstName : '',
                    lastName: guest ? guest.lastName : '',
                    email: guest ? guest.email : '',
                    nip: guest ? guest.nip : '',
                    phone: guest ? guest.phone : '',
                    address: guest ? guest.address : { city: '', street: '', number: '', postalCode: '' },
                }}
                onSubmit={(values) => onSubmit(values, guest === null)}
                onReset={(values, formikBag) => {
                    values.firstName = guest ? guest.firstName : '';
                    values.lastName = guest ? guest.lastName : '';
                    values.email = guest ? guest.email : '';
                    values.nip = guest ? guest.nip : '';
                    values.phone = guest ? guest.phone : '';
                    values.address = guest ? guest.address : { city: '', street: '', number: '', postalCode: '' };
                }}
                validate={values => {
                    const errors = {}
                    //validators here
                    return errors
                }}
                initialTouched={false}
            >
                {({
                    dirty,
                    touched,
                    handleReset
                }) =>
                    <Form>
                        <label>Imie</label>
                        <Field
                            disabled={!edit}
                            required
                            type="text"
                            name="firstName"
                            placeholder="Imie"
                        />
                        <br />
                        <label>Nazwisko</label>
                        <Field
                            disabled={!edit}
                            required
                            type="text"
                            name="lastName"
                            placeholder="Nazwisko"
                        />
                        <br />
                        <label>adres email</label>
                        <Field
                            disabled={!edit}
                            required
                            type="mail"
                            name="email"
                            placeholder="email (ex@adres.com)"
                        />
                        <br />
                        <label>NIP</label>
                        <Field
                            disabled={!edit}
                            type="text"
                            name="nip"
                            placeholder="ex. 1234567890"
                        />
                        <br />
                        <label>Nr. Telefonu</label>
                        <Field
                            disabled={!edit}
                            required
                            type="text"
                            name="phone"
                            placeholder="ex. +48 111222333"
                        />
                        <br />
                        <label>Adres</label>
                        <br />
                        <Field
                            disabled={!edit}
                            type="text"
                            name="address.postalCode"
                            placeholder="kod pocztowy"
                        />
                        <Field
                            disabled={!edit}
                            type="text"
                            name="address.city"
                            placeholder="miasto"
                        />
                        <br />
                        <Field
                            disabled={!edit}
                            type="text"
                            name="address.street"
                            placeholder="ulica"
                        />
                        <Field
                            disabled={!edit}
                            type="text"
                            name="address.number"
                            placeholder="numer (np. 11B)"
                        />
                        <br />
                        <br />
                        {!edit &&
                            <>
                                <label>ID polecajacego</label>
                                <h5>{window.location.host}/invite/{guest.invitationRef}</h5>
                                <button type="button" onClick={() => onNotifyMail(guest.id)}>Wyślij przypomnienie E-mail</button>
                                <br />
                            </>
                        }

                        {edit ?
                            <>
                                <button type="submit">{guest ? "Zapisz" : "Dodaj"}</button>
                                {guest ? <button type="button" onClick={handleReset}>Przywroc zmiany</button> : null}
                            </>
                            :
                            <></>
                        }
                        <button type="button" onClick={() => {
                            if (dirty) {
                                let opt = window.confirm(`masz niezapisane zmiany, opuscic \n Zmienione pola: ${Object.keys(touched).join(' ')}`)
                                if (!opt) {
                                    return
                                }
                            }
                            onCancel()
                        }}>cancel</button>
                        {guest ? <button type="button" onClick={() => onDelete(guest)}>Usun goscia</button> : null}
                    </Form>
                }
            </Formik>

        </div>
    )
}